/* eslint-disable */

<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-4">
      <vx-card class="">
        <div class="vx-row my-12">
          <div class="vx-col w-1/3 flex justify-center items-center">
            <div class="server-block flex flex-col items-center align-middle" style="background-color: #5f27cd; min-width: 14vw">
              <h1 class="font-bold w-full text-center mb-5 break-all">Internal</h1>

              <div class="border-solid p-2 rounded-lg flex flex-col items-center justify-center mb-5 w-4/5">
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2">Server3</h3>
                <h5>192.168.50.5</h5>
                <h5>Linux</h5>
              </div>

              <div
                class="border-solid p-2 rounded-lg flex flex-col items-center justify-center w-4/5 cursor-pointer"
                style="background: rgba(0, 0, 0, 0.28)"
                @click="togglePopup('serverFourPopup')"
              >
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2">Server4</h3>
                <h5>192.168.50.6</h5>
                <h5 class="mb-2">Windows</h5>
                <div class="flex justify-center items-center">
                  <vs-switch v-model="serverSwitches.serverFour.value" class="self-center" />
                  <feather-icon
                    v-if="serverSwitches.serverFour.answer === false && serverSwitches.serverFour.marked === false"
                    icon="XCircleIcon"
                    svgClasses="w-8 h-8 ml-4 text-danger self-center"
                  />
                </div>
              </div>
            </div>
            <div class="line-across"></div>
          </div>
          <div class="vx-col w-1/3 flex justify-center items-center">
            <div class="server-block flex items-center align-middle" style="background-color: #ff9f43">
              <div class="tenth-image-block ml-2">
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wall-2.png"
                  width="60"
                />
              </div>
              <div class="ml-4 mr-2">
                <h3 class="break-all">Firewall</h3>
              </div>
            </div>
          </div>
          <div class="vx-col w-1/3 flex justify-center items-center">
            <div class="server-block flex flex-col items-center align-middle" style="background-color: #54a0ff; min-width: 14vw">
              <h1 class="font-bold w-full text-center mb-5 break-all">DMZ</h1>

              <div
                class="border-solid p-2 rounded-lg flex flex-col items-center justify-center mb-5 w-4/5 cursor-pointer"
                style="background: rgba(0, 0, 0, 0.28)"
                @click="togglePopup('serverOnePopup')"
              >
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2">Server1</h3>
                <h5>10.1.1.2</h5>
                <h5 class="mb-2">Windows</h5>
                <div class="flex justify-center items-center">
                  <vs-switch v-model="serverSwitches.serverOne.value" class="self-center" />
                  <feather-icon
                    v-if="serverSwitches.serverOne.answer === false && serverSwitches.serverOne.marked === false"
                    icon="XCircleIcon"
                    svgClasses="w-8 h-8 ml-4 text-danger self-center"
                  />
                  <feather-icon
                    v-if="serverSwitches.serverOne.marked === true"
                    icon="CheckCircleIcon"
                    svgClasses="w-8 h-8 ml-4 text-success self-center"
                  />
                </div>
              </div>

              <div
                class="border-solid p-2 rounded-lg flex flex-col items-center justify-center w-4/5 cursor-pointer"
                style="background: rgba(0, 0, 0, 0.28)"
                @click="togglePopup('serverTwoPopup')"
              >
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2">Server2</h3>
                <h5>10.1.1.3</h5>
                <h5 class="mb-2">Windows</h5>
                <div class="flex justify-center items-center">
                  <vs-switch v-model="serverSwitches.serverTwo.value" class="self-center" />
                  <feather-icon
                    v-if="serverSwitches.serverTwo.answer === false && serverSwitches.serverTwo.marked === false"
                    icon="XCircleIcon"
                    svgClasses="w-8 h-8 ml-4 text-danger self-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full">
      <vx-card class="">
        <div class="border-white border-solid border-2 rounded">
          <div
            :class="[processListComplete === false ? 'bg-danger-gradient' : '', processListComplete === true ? 'bg-success-gradient' : '']"
            class="flex items-center justify-between p-5 bg-primary-gradient"
          >
            <div class="flex">
              <feather-icon icon="GridIcon" svgClasses="w-8 h-8 mr-1 self-center" />
              <h3 class="font-bold self-center ml-1">Process List</h3>
            </div>
            <div class="flex">
              <feather-icon v-if="processListComplete === true" icon="CheckCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
              <feather-icon v-if="processListComplete === false" icon="XCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
            </div>
          </div>
          <div class="p-5 pt-3 pb-3 grid grid-cols-3 gap-4">
            <div v-for="item in processList" :key="item.name" class="p-3 my-2">
              <div class="flex justify-between items-center">
                <div class="flex justify-between items-center">
                  <vs-switch v-model="item.value" class="mr-2" color="success" />
                  <h3 class="ml-2 font-bold break-all">{{ item.name }}</h3>
                </div>
                <feather-icon
                  v-if="item.marked !== null && item.answer === true && item.marked === item.answer"
                  icon="CheckCircleIcon"
                  svgClasses="w-8 h-8 ml-4 text-success self-center"
                />
                <feather-icon
                  v-if="item.marked !== null && item.answer === false && item.marked === item.answer"
                  icon="XCircleIcon"
                  svgClasses="w-8 h-8 ml-4 text-danger self-center"
                />
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>

    <movable-popup
      refVal="movableServerOneRef"
      title="Server 1 Logs - Click Here to Drag This Popup"
      :active="serverOnePopup"
      v-on:close="serverOnePopup = false"
    >
      <template v-slot:content>
        <div>
          C:\Users\Team3>netstat -oan<br />
          <br />
          Active Connections<br />
          <br />
          Proto Local Address Foreign Address State PID<br />
          TCP 0.0.0.0:49154 0.0.0.0:0 LISTENING 884<br />
          TCP 0.0.0.0:49184 0.0.0.0:0 LISTENING 540<br />
          TCP 0.0.0.0:49190 0.0.0.0:0 LISTENING 532<br />
          TCP 10.1.1.2:57322 192.168.50.6:443 ESTABLISHED 1276<br />
          TCP 10.1.1.2:50125 192.168.50.6:445 ESTABLISHED 276<br />
          TCP 10.1.1.2:52349 192.168.50.6:139 ESTABLISHED 276<br />
          TCP 10.1.1.2:139 0.0.0.0:0 LISTENING 4<br />
          TCP 10.1.1.2:3389 172.30.0.148:49242 ESTABLISHED 348<br />
          TCP 10.1.1.2:3389 172.30.0.101:445 ESTABLISHED 4<br />
          TCP 10.1.1.2:50777 172.30.0.4:135 TIME_WAIT 0<br />
          TCP 10.1.1.2:50778 172.30.0.4:49157 TIME_WAIT 0<br />
          TCP [::]:135 [::]:0 LISTENING 540<br />
          TCP [::]:445 [::]:0 LISTENING 4<br />
          <br />
          C:\Users\Team3>tasklist<br />
          <br />
          Image Name PID Session Name Session# Mem Usage<br />
          ======================================<br />
          System Idle Process 0 Services 0 24 K<br />
          System 4 Services 0 1,340 K<br />
          smss.exe 300 Services 0 884 K<br />
          crsss.exe 384 Services 0 3,048 K<br />
          wininit.exe 432 Services 0 3,284 K<br />
          services.exe 532 Services 0 7,832 K<br />
          lsass.exe 540 Services 0 9,776 K<br />
          lsm.exe 560 Services 0 5,164 K<br />
          svchost.exe 884 Services 0 22,528 K<br />
          svchost.exe 276 Services 0 9,860 K<br />
          svchost.exe 348 Services 0 12,136 K<br />
          spoolsv.exe 1036 Services 0 8,216 K<br />
          svchost.exe 1068 Services 0 7,888 K<br />
          svchost.exe 2020 Services 0 17,324 K<br />
          notepad.exe 1276 Services 0 4,324 K<br />
          svchost.exe 1720 Services 0 3,172 K<br />
          SearchIndexer.exe 864 Services 0 14,968 K<br />
          OSPPSVC.EXE 2584 Services 0 13,764 K<br />
          csrss.exe 372 RDP-Tcp#0 1 7,556 K<br />
          winlogon.exe 460 RDP-Tcp#0 1 5,832 K<br />
          rdpclip.exe 1600 RDP-Tcp#0 1 4,356 K<br />
          dwm.exe 772 RDP-Tcp#0 1 5,116 K<br />
          taskhost.exe 1700 RDP-Tcp#0 0 8,720 K<br />
          explorer.exe 2500 RDP-Tcp#0 0 66,444 K<br />
          splwow64.exe 2960 RDP-Tcp#0 1 4,152 K<br />
          cmd.exe 1260 RDP-Tcp#0 1 2,652 K<br />
          conhost.exe 2616 RDP-Tcp#0 1 5,256 K<br />
          audioodg.exe 980 Services 0 13,256 K<br />
          csrss.exe 2400 Console 1 3,512 K<br />
          winlogon.exe 2492 Console 1 5,772 K<br />
          LogonUI.exe 2864 Console 3 17,056 K<br />
          notepad.exe 376 Services 0 5,636 K<br />
          taskhost.exe 2812 RDP-Tcp#0 1 9,540 K<br />
          tasklist.exe 1208 RDP-Tcp#0 1 5,196 K<br />
          wmiPrvSE.exe 1276 Services 0 5,776 K<br />
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableServerTwoRef"
      title="Server 2 Logs - Click Here to Drag This Popup"
      :active="serverTwoPopup"
      v-on:close="serverTwoPopup = false"
    >
      <template v-slot:content>
        <div>
          C:\Users\Team3>netstat -ano<br />
          <br />
          Active Connections<br />
          <br />
          Proto Local Address Foreign Address State PID<br />
          TCP 0.0.0.0:135 0.0.0.0:0 LISTENING 884<br />
          TCP 0.0.0.0:445 0.0.0.0:0 LISTENING 540<br />
          TCP 0.0.0.0:3389 0.0.0.0:0 LISTENING 532<br />
          TCP 10.1.1.2:49152 0.0.0.0:0 LISTENING 1276<br />
          TCP 10.1.1.2:49153 0.0.0.0:0 LISTENING 276<br />
          TCP 10.1.1.2:49154 0.0.0.0:0 LISTENING 276<br />
          TCP 10.1.1.2:49155 0.0.0.0:0 LISTENING 4<br />
          TCP 10.1.1.3:139 0.0.0.0:0 LISTENING 348<br />
          TCP 10.1.1.3:3389 192.168.50.5:49335 ESTABLISHED 4<br />
          TCP 10.1.1.3:50276 192.168.50.6:445 ESTABLISHED 0<br />
          TCP [::]:135 [::]:0 LISTENING 540<br />
          TCP [::]:445 [::]:0 LISTENING 4<br />
          TCP [::]:3389 [::]:0 LISTENING 4<br />
          <br />
          C:\Users\Team3>tasklist<br />
          <br />
          Image Name PID Session Name Session# Mem Usage<br />
          ======================================<br />
          System Idle Process 0 Services 0 24 K<br />
          System 4 Services 0 636 K<br />
          smss.exe 300 Services 0 900 K<br />
          crsss.exe 384 Services 0 3,252 K<br />
          wininit.exe 440 Services 0 3,272 K<br />
          services.exe 528 Services 0 8,212 K<br />
          lsass.exe 536 Services 0 10,140 K<br />
          lsm.exe 548 Services 0 5,360 K<br />
          svchost.exe 648 Services 0 6,572 K<br />
          svchost.exe 716 Services 0 6,472 K<br />
          svchost.exe 808 Services 0 14,372 K<br />
          svchost.exe 884 Services 0 44,856 K<br />
          svchost.exe 920 Services 0 22,580 K<br />
          svchost.exe 100 Services 0 8,700 K<br />
          svchost.exe 516 Services 0 13,236 K<br />
          spoolsv.exe 952 Services 0 9,964 K<br />
          svchost.exe 1060 Services 0 7,716 K<br />
          svchost.exe 904 Services 0 15,228 K<br />
          svchost.exe 2208 Services 0 3,156 K<br />
          SearchIndexer.exe 2252 Services 0 15,720 K<br />
          crsss.exe 848 Console 0 3,444 K<br />
          winlogon.exe 2864 Console 3 5,620 K<br />
          LogonUI.exe 1976 Console 3 17,080 K<br />
          csrss.exe 1408 RDP-Tcp#0 1 5,256 K<br />
          winlogon.exe 1520 RDP-Tcp#0 1 6,228 K<br />
          rdpclip.exe 1380 RDP-Tcp#0 1 4,504 K<br />
          dwm.exe 2656 RDP-Tcp#0 1 4,132 K<br />
          explorer.exe 2328 RDP-Tcp#0 1 58,948 K<br />
          taskhost.exe 1396 RDP-Tcp#0 1 5,504 K<br />
          conhost.exe 472 RDP-Tcp#0 1 5,120 K<br />
          conhost.exe 3004 RDP-Tcp#0 1 5,204 K<br />
          tasklist.exe 308 RDP-Tcp#0 1 5,180 K<br />
          wmiPrvSE.exe 372 Services 0 5,780 K<br />
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableServerFourRef"
      title="Server 4 Logs - Click Here to Drag This Popup"
      :active="serverFourPopup"
      v-on:close="serverFourPopup = false"
    >
      <template v-slot:content>
        <div>
          C:\Users\Team3>netstat -oan<br />
          <br />
          Active Connections<br />
          <br />
          Proto Local Address Foreign Address State PID<br />
          TCP 0.0.0.0:49154 0.0.0.0:0 LISTENING 636<br />
          TCP 0.0.0.0:49184 0.0.0.0:0 LISTENING 540<br />
          TCP 0.0.0.0:49190 0.0.0.0:0 LISTENING 532<br />
          TCP 192.168.50.6:443 10.1.1.2:57433 ESTABLISHED 348<br />
          TCP 192.168.50.6:445 10.1.1.2:50125 ESTABLISHED 540<br />
          TCP 192.168.50.6:139 10.1.1.2:52349 ESTABLISHED 540<br />
          TCP 192.168.50.6:139 0.0.0.0:0 LISTENING 4<br />
          TCP 192.168.50.6:3389 172.30.0.148:49242 ESTABLISHED 348<br />
          TCP 192.168.50.6:50741 172.30.0.101:445 ESTABLISHED 4<br />
          TCP 192.168.50.6:50777 172.30.0.4:135 TIME_WAIT 0<br />
          TCP 192.168.50.6:50778 172.30.0.4:49157 TIME_WAIT 0<br />
          TCP [::]:135 [::]:0 LISTENING 1720<br />
          TCP [::]:445 [::]:0 LISTENING 4<br />
          TCP [::]:3389 [::]:0 LISTENING 348<br />
          <br />
          C:\Users\Team3>tasklist<br />
          <br />
          Image Name PID Session Name Session# Mem Usage<br />
          ======================================<br />
          System Idle Process 0 Services 0 24 K<br />
          System 4 Services 0 1,340 K<br />
          smss.exe 300 Services 0 884 K<br />
          crsss.exe 384 Services 0 3,048 K<br />
          wininit.exe 432 Services 0 3,284 K<br />
          services.exe 532 Services 0 7,832 K<br />
          lsass.exe 540 Services 0 9,776 K<br />
          lsm.exe 560 Services 0 5,164 K<br />
          svchost.exe 636 Services 0 6,864 K<br />
          svchost.exe 348 Services 0 12,136 K<br />
          spoolsv.exe 1036 Services 0 8,216 K<br />
          svchost.exe 1068 Services 0 7,888 K<br />
          svchost.exe 2020 Services 0 17,324 K<br />
          svchost.exe 1720 Services 0 3,172 K<br />
          SearchIndexer.exe 864 Services 0 14,968 K<br />
          OSPPSVC.EXE 2584 Services 0 13,764 K<br />
          csrss.exe 372 RDP-Tcp#0 1 7,556 K<br />
          winlogon.exe 460 RDP-Tcp#0 1 5,832 K<br />
          rdpclip.exe 1600 RDP-Tcp#0 1 4,356 K<br />
          dwm.exe 772 RDP-Tcp#0 1 5,116 K<br />
          spoolsv.exe 1036 Services 0 8,216 K<br />
          svchost.exe 1068 Services 0 7,888 K<br />
          svchost.exe 2020 Services 0 17,324 K<br />
          svchost.exe 1720 Services 0 3,172 K<br />
          SearchIndexer.exe 864 Services 0 14,968 K<br />
          OSPPSVC.EXE 2584 Services 0 13,764 K<br />
          csrss.exe 372 RDP-Tcp#0 1 7,667 K<br />
          winlogon.exe 460 RDP-Tcp#0 1 5,832 K<br />
          rdpclip.exe 1600 RDP-Tcp#0 1 4,356 K<br />
          dwm.exe 772 RDP-Tcp#0 1 5,116 K<br />
          taskhost.exe 1700 RDP-Tcp#0 1 8.720 K<br />
          explorer.exe 2500 RDP-Tcp#0 1 66,444 K<br />
          splwow64.exe 2960 RDP-Tcp#0 1 4,152 K<br />
          cmd.exe 1260 RDP-Tcp#0 1 2,652 K<br />
          conhost.exe 2616 RDP-Tcp#0 1 5,256 K<br />
          audioodg.exe 980 Services 0 13,256 K<br />
          csrss.exe 2400 Console 3 3,512 K<br />
          winlogon.exe 2492 Console 3 5,772 K<br />
          LogonUI.exe 2864 Console 3 17,056 K<br />
          taskhost.exe 2812 Console 0 9,540 K<br />
          tasklist.exe 1208 RDP-Tcp#0 1 5,196 K<br />
          wmiPrvSE.exe 1276 Services 0 5,776 K<br />
          <br />
        </div>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      processList: [
        {
          name: 'Lsass.exe',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'Svchost.exe',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'Notepad.exe',
          value: false,
          answer: true,
          marked: null,
        },
        {
          name: 'Explorer.exe',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'Lsm.exe',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'Searchindexer.exe',
          value: false,
          answer: false,
          marked: null,
        },
      ],
      processListComplete: null,

      serverSwitches: {
        serverOne: {
          value: false,
          answer: true,
          marked: null,
        },
        serverTwo: {
          value: false,
          answer: false,
          marked: null,
        },
        serverFour: {
          value: false,
          answer: false,
          marked: null,
        },
      },

      serverOnePopup: false,
      serverTwoPopup: false,
      serverFourPopup: false,
    };
  },
  methods: {
    togglePopup(popup) {
      if (this[popup] === true) {
        this[popup] = 'transition_out';
        return setTimeout(() => {
          this[popup] = false;
        }, 400);
      }
      this[popup] = true;
    },
    markSimulation() {
      let success = true;
      let totalScore = 0;

      this.processList.forEach((question, index) => {
        if (question.value === question.answer) {
          this.processList[index].marked = true;
          totalScore++;
        } else {
          success = false;
          this.processList[index].marked = false;
        }
      });
      this.processListComplete = success;

      for (const [key, value] of Object.entries(this.serverSwitches)) {
        if (value.value === value.answer) {
          this.serverSwitches[key].marked = true;
          totalScore++;
        } else {
          this.serverSwitches[key].marked = false;
        }
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    MovablePopup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Cloud+//Lgx209045111810555904.scss';

.line-across {
  position: absolute;
  width: 75%;
  height: 4px;
  margin-left: 67%;
  background-color: #0285e4;
}

.server-block {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  min-width: 165px;
  padding: 10px;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  background-color: #3b3b98;
  box-shadow: 1px 1px 3px 0 #272727;
  border-radius: 0.5rem;
  -o-object-fit: fill;
  object-fit: fill;
}

.vs-switch {
  background: #000000ab;
}
</style>
